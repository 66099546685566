<template>
  <div :style="isIOS ? {} : { height: '100%', width: '100%' }">
    <div v-if="isIOS">
      <pdf
        v-for="i in content[type].numPages"
        :key="i"
        :page="i"
        :src="require(`@/assets/pdf/${content[type].name}`)"
      ></pdf>
    </div>
    <iframe
      v-else
      :src="require(`@/assets/pdf/${content[type].name}`)"
      width="100%"
      height="100%"
      type="application/pdf"
    />
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: ["type"],
  components: { pdf },
  data: () => ({
    content: {
      "Contest Regulation": { name: "ContestRegulation.pdf", numPages: 5 },
      "Privacy Policy": { name: "PrivacyPolicy.pdf", numPages: 7 },
      "Terms and Conditions": { name: "Terms&Conditions.pdf", numPages: 11 },
      "Cookies Policy": { name: "CookiesPolicy.pdf", numPages: 4 },
    },
  }),
  created() {
    if (this.isIOS) document.getElementById("vapp").style.overflowY = "scroll";
    this.$store.commit("currentPage", { page: "about" });
  },
  beforeDestroy() {
    if (this.isIOS) document.getElementById("vapp").style.overflowY = "hidden";
  },
};
</script>

<style scoped></style>
